import { Heading, SubHeading } from "../../components/headings/headings";
import Input from "../../components/input/input";
import Body, { CustomHeightContainer } from "../../components/body/body";
import Button from "../../components/button/button";
import ArrowRightIcon from "../../assets/arrow-right.svg";
import emailjs from '@emailjs/browser';
import { useState } from "react";
import { toast } from "react-toastify";
import { getErrorMobileNumber } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { updateValidMobileNumber } from "../../lib/redux/slices/registration-enquiry.slice";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../../components/phone-number-input";


const RegistrationEnquiry = () => {
  const dispatch = useDispatch();
  const registrationEnquiry = useSelector((state) => state.registrationEnquiry);
  const navigate = useNavigate();

  const [shopName, setShopName] = useState('')
  const [ownersName, setOwnersName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const sendEmail = async () => {
    setSubmitting(true)
    const error = getErrorMobileNumber({shopOwnerContactNumber:contactNumber})
    if(error) {
      setSubmitting(false)
      return toast.error("Please provide a valid shop owner contact number")
    }

    if(registrationEnquiry.validMobileNumber.includes(contactNumber)) {
      setSubmitting(false)
      return toast.error("You have already submitted the details with this mobile number.") 
    }
    try {
      const templateParams = {
        shopName: shopName,
        ownersName: ownersName,
        contactNumber: contactNumber
      };

      await emailjs
            .send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID, 
              process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
              templateParams, {
              publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            })
      toast.success("Submitted successfully")
      setSubmitting(false)  
      dispatch(
        updateValidMobileNumber({
          validMobileNumber: [...registrationEnquiry.validMobileNumber, contactNumber],
        })
      ); 
      navigate("/successful-thank-you", {state: {success: true}})
    } catch(err) {
      toast.error("Failed to Submit.")
      setSubmitting(false)
    }
  };

  
  return (
    <>
      <CustomHeightContainer>
        <Body>
          <div>
            <Heading>Get Your Local Shop Online with DODONG</Heading>
            <div className="my-2">
              <SubHeading>
                Fill out the form below, and our support team will contact you to help your shop go online.
              </SubHeading>
            </div>

            <div className="flex gap-10 w-full mt-7 flex-col md:flex-row">

              <div className="md:w-1/2 flex flex-col gap-4">
                <Input
                  label="Shop Name:"
                  name="shopName"
                  value={shopName}
                  type="text"
                  onChange={(e)=> setShopName(e.target.value)}
                />

                <Input
                  label="Owner’s Name"
                  name="ownerName"
                  value={ownersName}
                  type="text"
                  onChange={(e)=> setOwnersName(e.target.value)}
                />

                <PhoneNumberInput
                  label="Contact Number:"
                  value={contactNumber}
                  onChange={(value)=> setContactNumber(value)}
                /> 
              </div>
            </div>
  
          </div>
        </Body>
      </CustomHeightContainer>
      <div className="font-light text-sm md:pt-6 md:pr-14 md:pb-0 md:pl-14 pt-6 pr-3 pb-0 pl-3 my-5">
        Our team will get in touch with you within 24 hours to assist you in taking your shop online.
      </div>
      <div className="flex items-center justify-end border-t relative bottom-0 w-full md:pt-6 md:px-14 md:pb-8 gap-3 pt-5 px-2 pb-5">
        <div>
          <Button disabled={!shopName || !ownersName || !contactNumber || submitting} onClick={sendEmail}>
            <div className="flex items-center justify-center">
              <span>{submitting ? 'Submitting...' : 'Submit'}</span>
              
                <div className="ml-1">
                  <img src={ArrowRightIcon} alt="arrow-right-icon" />
                </div>
              
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationEnquiry;
